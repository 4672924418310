import { MagnifyingGlass, Minus, Plus, Spinner } from "@phosphor-icons/react";
import { Modal, Tooltip } from "antd";
import clsx from "clsx";
import toast from "components/Commons/Toaster";
import When from "components/Commons/When";
import useDocumentFromS3 from "hooks/useDocumentFromS3";
import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Document, DocumentProps, Page, PasswordResponses, pdfjs } from "react-pdf";
import { useEventListener, useLocalStorage, useResizeObserver } from "usehooks-ts";
import { MIXPANEL_EVENTS, Mixpanel } from "utils/mixpanel";
import "./styles.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDF_PAGES_SCALE_OFFSET = 0.05;

const DocLoadingLoader = () => (
	<div className="absolute inset-0 grid h-screen max-h-full place-items-center ">
		<Spinner size={32} className="spin text-primary-600" />
	</div>
);

type Size = {
	width?: number;
	height?: number;
};
export type TDocPreviewPropsType = {
	className?: string;
	disableControls?: boolean;
	s3Path?: string;
	file?: File;
	isThumbnail?: boolean;
	defaultScale?: number;
};

const DocPreview: React.FC<TDocPreviewPropsType> = ({
	className,
	disableControls,
	s3Path,
	file,
	isThumbnail,
	defaultScale = 1
}) => {
	const { isLoading, src: _src, isPdf: _isPdf } = useDocumentFromS3(s3Path);
	const [pdfPagesCount, setPdfPagesCount] = useState<number>();
	const [pdfPagesVisible, setPdfPagesVisible] = useState<number[]>([]);
	const [zoom, setZoom] = useLocalStorage<number>("doc-preview-zoom", defaultScale);
	const [scale, setScale] = useState<number>(defaultScale);
	const previewRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const intervalRef = useRef<NodeJS.Timer>();

	const isPdf = useMemo(() => _isPdf || (file && file.type === "application/pdf"), [_isPdf, file]);
	const src = useMemo(() => _src || (file && URL.createObjectURL(file)), [_src, file]);

	const pdfPageObserver = useMemo(() => {
		return new IntersectionObserver(
			(entries) => {
				entries.forEach((_) => {
					const child = _.target.firstChild as HTMLDivElement;
					if (!child) {
						return console.log("🚀 ~ file: index.tsx:40 ~ entries.forEach ~ child:", child);
					}
					const pageNumber = child.dataset.pageNumber ? parseInt(child.dataset.pageNumber) : 0;
					setPdfPagesVisible((prev) => {
						if (pageNumber === 0) return prev;
						if (_.isIntersecting) {
							if (!prev.includes(pageNumber)) {
								return [...prev, pageNumber].sort((a, b) => a - b);
							}
							return prev;
						} else {
							return prev.filter((_) => _ !== pageNumber).sort((a, b) => a - b);
						}
					});
				});
			},
			{ threshold: 0.1 }
		);
	}, []);
	const [{ width, height }, setSize] = useState<Size>({
		width: undefined,
		height: undefined
	});

	useLayoutEffect(() => {
		if (!previewRef.current) return;
		const { width, height } = previewRef.current.getBoundingClientRect();
		setSize({ width, height });
	}, [isLoading, src]);

	useEffect(() => {
		setScale(isThumbnail ? defaultScale : zoom);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isThumbnail]);

	useResizeObserver({ ref: previewRef, onResize: setSize });

	const pageImageWidth = isThumbnail ? width || 600 : 600;
	const pageImageHeight = isThumbnail ? height || 600 : 600;

	const handlePdfLoadSuccess: DocumentProps["onLoadSuccess"] = (_) => {
		setPdfPagesCount(_.numPages);
	};

	useEffect(() => {
		if (!s3Path && !file) return;
		setPdfPagesCount(0);
		setPdfPagesVisible([]);
		pdfPageObserver.disconnect();
	}, [s3Path, file, pdfPageObserver]);

	useEventListener("keydown", (event) => {
		if (disableControls) return;
		// switchPage only if focused element is body
		if (event.ctrlKey === true && window.document.activeElement === window.document.body) {
			switch (event.key) {
				case "=":
					handleScaleIncrease();
					event.preventDefault();
					break;
				case "-":
					handleScaleDecrease();
					event.preventDefault();
					break;
				case "0":
					setScale(defaultScale);
					event.preventDefault();
					break;

				default:
					// do nothing
					break;
			}
		}
	});

	const handleScaleIncrease = (scaleUnit = PDF_PAGES_SCALE_OFFSET) => {
		Mixpanel.track(MIXPANEL_EVENTS.ZOOM_SCALE_DOCUMENT, { scaleUnit });
		setScale((prev) => {
			const newScale = prev + scaleUnit;
			setZoom(newScale);
			return newScale;
		});
	};

	const handleScaleDecrease = (scaleUnit = PDF_PAGES_SCALE_OFFSET) => {
		setScale((prev) => {
			const newScale = prev - scaleUnit;
			setZoom(newScale);
			return newScale;
		});
	};

	const handleMouseDown = (fn: () => void) => () => {
		clearInterval(intervalRef.current);
		intervalRef.current = setInterval(fn, 100);
	};

	const handleMouseUp = () => {
		clearInterval(intervalRef.current);
	};
	const handleMouseLeave = () => {
		clearInterval(intervalRef.current);
	};

	const [isMoving, setIsMoving] = useState(false);
	const [zoomingState, setZoomingState] = useState<"+" | "-" | 0>(0);

	useEventListener(
		"wheel",
		(event) => {
			if (disableControls) return;
			if (!event.ctrlKey) return setZoomingState(0);
			event.preventDefault();
			if (event.deltaY < 0) {
				setZoomingState("+");
				handleScaleIncrease(0.02);
			} else if (event.deltaY > 0) {
				setZoomingState("-");
				handleScaleDecrease(0.02);
			}
			setIsMoving(false);
		},
		containerRef
	);

	useEventListener(
		"mousemove",
		(event) => {
			if (disableControls) return;
			if (!event.ctrlKey) return setIsMoving(false);
			event.preventDefault();
			setIsMoving(true);
			setZoomingState(0);
		},
		containerRef
	);

	useEventListener(
		"mouseleave",
		(event) => {
			if (disableControls) return;
			setIsMoving(false);
			setZoomingState(0);
		},
		containerRef
	);

	return (
		<div
			className={clsx(
				"scrollbar-hidden overflow-hidden relative grid h-full w-full",
				isThumbnail &&
					"[&>*]:p-0 [&>*]:grid [&>*]:overflow-hidden [&>*]:place-items-stretch [&_img]:size-full [&_img]:object-cover [&_.content]:p-0",
				className
			)}
			ref={previewRef}>
			{isLoading && <DocLoadingLoader />}

			{!isLoading && src && (
				<div
					className={clsx(
						"viewbox row-span-full col-span-full h-inherit scrollbar-hidden overflow-auto",
						isThumbnail ? "relative" : "absolute inset-0"
					)}>
					<div
						ref={containerRef}
						className={clsx(
							"content px-3 py-16 min-h-full min-w-full text-center grid place-items-center",
							isThumbnail ? "relative" : "absolute",
							isMoving && "cursor-move",
							!isMoving && zoomingState === "+" && "cursor-zoom-in",
							!isMoving && zoomingState === "-" && "cursor-zoom-out"
						)}>
						<When isTrue={isPdf}>
							<Document
								className="document flex flex-col items-center gap-8"
								file={src}
								onPassword={(callback, reason) => {
									switch (reason) {
										case PasswordResponses.NEED_PASSWORD: {
											Modal.confirm({
												title: "Enter password",
												content: (
													<input
														type="password"
														className="w-full p-2 border border-gray-300 rounded-lg"
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																callback(e.currentTarget.value);
															}
														}}
													/>
												),
												okText: "Submit",
												onOk: (close) => {
													const password = (
														document.querySelector(
															"input[type='password']"
														) as HTMLInputElement
													)?.value;
													if (!password) return toast.error("Password is required.");
													callback(password);
													close();
												}
											});
											break;
										}
										case PasswordResponses.INCORRECT_PASSWORD: {
											toast.error("Invalid password. Please try again.");
											break;
										}
										default:
									}
								}}
								loading={<DocLoadingLoader />}
								onLoadSuccess={handlePdfLoadSuccess}>
								{pdfPagesCount &&
									Array.from({ length: isThumbnail ? 1 : pdfPagesCount }, (_, key) => (
										<div
											key={key}
											ref={(ele) => {
												if (ele) {
													pdfPageObserver.observe(ele);
												}
											}}
											className="mx-auto max-w-full transition-[transform]">
											<Page
												pageIndex={key}
												renderTextLayer={false}
												renderAnnotationLayer={false}
												loading={
													<div className="h-screen max-h-full">
														<DocLoadingLoader />
													</div>
												}
												_className="document-preview"
												width={pageImageWidth * scale}
												height={pageImageHeight * scale}
											/>
										</div>
									))}
							</Document>
						</When>
						<When isTrue={!isPdf}>
							<div
								className="grid place-items-stretch mx-auto min-h-full"
								style={{
									width: scale * pageImageWidth + "px"
									// height: isThumbnail ? scale * pageImageHeight + "px" : `unset`
								}}>
								<img
									src={src}
									alt="Document"
									loading="lazy"
									className="max-w-none object-contain h-full w-full"
								/>
							</div>
						</When>
					</div>
				</div>
			)}

			{!disableControls && (
				<div className="controls h-8 min-h-8 max-h-8 bg-gradient-to-b from-white/10 to-white/0 rounded-lg bg-[#1B1B1F] ring-1 ring-inset ring-white/20 ring-offset-1 ring-offset-[#1B1B1F] shadow-[0_10px_15px_-3px_rgb(0_0_0_/_0.1),0_4px_6px_-4px_rgb(0_0_0_/_0.1),0px_-2px_0px_black_inset] overflow-clip self-center flex items-center justify-self-center absolute bottom-6 ">
					<div className="flex items-center">
						{!!pdfPagesCount && (
							<>
								<span className="h-8 text-xs text-white grid place-items-center drop-shadow-button-icon cursor-pointer px-2">
									Page {pdfPagesVisible.length === 0 ? "0" : pdfPagesVisible.join(" - ")} of{" "}
									{pdfPagesCount}
								</span>
								<div className="border-l-gray-900/85 border-r-white/20 border-l border-r h-8"></div>
							</>
						)}
						<Tooltip title="Zoom out (Ctrl + -)">
							<Minus
								role="button"
								size={14}
								weight="bold"
								className="size-8 text-white grid place-items-center drop-shadow-button-icon cursor-pointer hover:bg-gray-900/20 p-2"
								onClick={() => handleScaleDecrease()}
								onMouseDown={handleMouseDown(handleScaleDecrease)}
								onMouseUp={handleMouseUp}
								onMouseLeave={handleMouseLeave}
							/>
						</Tooltip>
						<Tooltip title="Zoom reset (Ctrl + 0)">
							<MagnifyingGlass
								size={14}
								weight="bold"
								onClick={() => setScale(1)}
								className="size-8 text-white grid place-items-center drop-shadow-button-icon cursor-pointer hover:bg-gray-900/20 p-2"
							/>
						</Tooltip>
						<Tooltip title="Zoom in (Ctrl + +)">
							<Plus
								role="button"
								size={14}
								weight="bold"
								className="size-8 text-white grid place-items-center drop-shadow-button-icon cursor-pointer hover:bg-gray-900/20 p-2"
								onClick={() => handleScaleIncrease()}
								onMouseDown={handleMouseDown(handleScaleIncrease)}
								onMouseUp={handleMouseUp}
								onMouseLeave={handleMouseLeave}
							/>
						</Tooltip>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(DocPreview);

